<template>
  <div id="app" :class="['app ' + $route.name, { isAuth: isAuth }]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <Header />
        <router-view
          @setPaymentMethod="setPaymentMethod"
          :currencies="currencies"
          :crypto="crypto"
          :rates="rates"
          @setCheckoutData="setCheckoutData"
          @setWallet="setWallet"
        />
        <Footer :footerImages="footerImages" />
        <transition name="fade">
          <SignInModal
            v-if="signInModalIsVisible"
            @closeSignInModal="closeSignInModal"
            @login="login"
          />
        </transition>
        <transition name="fade">
          <RecoverModal
            v-if="recoverModalIsVisible"
            @closeRecover="closeRecover"
            @forgotPass="forgotPass"
          />
        </transition>
        <transition name="fade">
          <RegisterModal
            v-if="registerModalIsVisible"
            @closeRegister="closeRegister"
            @registration="registration"
          />
        </transition>
        <transition name="fade">
          <PersonalInfoModal
            v-if="personalInfoModalIsVisible"
            @closePersonalInfoModal="closePersonalInfoModal"
            @orderSubmit="orderSubmit"
            @setPersonalInfo="setPersonalInfo"
          />
        </transition>
        <cookie-modal></cookie-modal>
      </div>
    </transition>
    <Chatbot />
  </div>
</template>

<script>
import router from "@/router";
import CookieModal from "./components/CookieModal.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import SignInModal from "./components/SignInModal.vue";
import RecoverModal from "./components/RecoverModal.vue";
import RegisterModal from "./components/RegisterModal.vue";
import PersonalInfoModal from "./components/PersonalInfoModal.vue";
import Chatbot from "@/components/BotPressChat.vue";
import { mapActions } from "vuex";

export default {
  name: "app",
  components: {
    Header,
    Footer,
    SignInModal,
    RecoverModal,
    RegisterModal,
    PersonalInfoModal,
    CookieModal,
    Chatbot,
  },
  data: function () {
    return {
      pageLoaded: false,
      headerNav: [
        {
          title: "About Us",
          path: "/about-us",
        },
        {
          title: "FAQ’s",
          path: "/faq",
        },
        {
          title: "Contact",
          path: "/contact",
        },
        // {
        //   title: 'Exchange',
        //   path: '/buy-crypto'
        // }
      ],
      footerNav: [
        {
          title: "FAQ’s",
          path: "/faq",
        },
      ],
      signInModalIsVisible: false,
      registerModalIsVisible: false,
      recoverModalIsVisible: false,
      error: "",
      isAuth: false,
      emailVerified: false,
      successMessage: "",
      currencies: {},
      crypto: {},
      rates: [],
      wallets: [],
      orderHistory: [],
      giveAmount: 0,
      takeAmount: 0,
      takeCurrency: {},
      giveCurrency: "",
      paymentMethod: "",
      fiatToCrypto: true,
      personalInfoModalIsVisible: false,
      wallet: "",
      phone: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      birthDate: "",
      name: "",
      surname: "",
      email: "",
      faq: [],
      textPageList: [],
      socialLinks: [],
      hostname: "",
      textPageTitle: "",
      textPageHeader: "",
      textPageContent: "",
      checkoutSuccess: false,
      companyEmail: "",
      companyPhone: "",
      billingDescriptor: "",
      // minOrderPriceFiatToCrypto: null,
      minOrderPriceCryptoToFiat: null,
      legalInfo: "",
      paymentMethods: [],
      appDomain: "",
      topOrderLimit: null,
      footerImages: [],
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    ...mapActions("app", ["fetchLanguages"]),

    checkAuthStatus() {
      this.$http
        .get(process.env.VUE_APP_API + "is-auth")
        .then((res) => {
          this.isAuth = res.data.authenticated;
          this.emailVerified = res.data.emailVerified;
          localStorage.setItem("isAuthenticated", this.isAuth.toString());
          localStorage.setItem("emailVerified", this.emailVerified.toString());

          if (!this.isAuth && this.$route.name === "OrderRequisites") {
            this.$router.push({
              name: "Home",
              query: { redirect: this.$route.path },
            });
          } else if (this.isAuth && !this.emailVerified) {
            if (router.currentRoute.name != "EmailVerificationNotice") {
              this.$router.push({ name: "EmailVerificationNotice" });
            }
           
          }
        })
        .catch(() => {
          this.isAuth = false;
          localStorage.setItem("isAuthenticated", false);
          localStorage.setItem("emailVerified", false);
          localStorage.removeItem("authToken");
        });
    },
    isEmailVerified() {
      return JSON.parse(localStorage.getItem("emailVerified") || "false");
    },
    setPaymentMethod(type) {
      this.paymentMethod = type;
    },
    getDomainName(hostName) {
      return hostName.substring(
        hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
      );
    },
    clearError() {
      this.error = "";
    },
    openSignInModal() {
      this.registerModalIsVisible = false;
      this.recoverModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openRecover() {
      this.signInModalIsVisible = false;
      this.registerModalIsVisible = false;
      this.recoverModalIsVisible = true;
    },
    closeRecover() {
      this.recoverModalIsVisible = false;
      this.clearError();
    },
    openRegister() {
      this.signInModalIsVisible = false;
      this.recoverModalIsVisible = false;
      this.registerModalIsVisible = true;
    },
    closeRegister() {
      this.registerModalIsVisible = false;
      this.clearError();
    },
    login(data) {
      let self = this;
      self.$http
        .get(process.env.VUE_APP_DOMAIN + "sanctum/csrf-cookie")
        .then(() => {
          self.$http
            .post(process.env.VUE_APP_API + "login", data)
            .then((res) => {
              if (res.data.status == "OK") {
                // self.fetchProfile();
                self.isAuth = true;
                localStorage.setItem("isAuthenticated", true.toString());
                localStorage.setItem("authToken", res.data.access_token);
                localStorage.setItem(
                  "emailVerified",
                  res.data.user.email_verified_at !== null
                );
                self.clearError();
                self.closeSignInModal();

                if (this.$route.query["redirect"]) {
                  self.$router.push({ path: self.$route.query["redirect"] });
                } else {
                  self.$router.push({ path: "/buy-crypto" });
                }
              } else {
                self.error = res.data.message;
              }
            })
            .catch((res) => {
              self.error = res.response.data.message;
              this.isAuth = false;
              localStorage.setItem("isAuthenticated", false.toString());
              localStorage.setItem("emailVerified", false.toString());
              localStorage.removeItem("authToken");
            });
        });
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        self.error = "Password confirmation failed!";
      } else {
        self.$http
          .post(process.env.VUE_APP_API + "register", data)
          .then((res) => {
            if (res.data.status == "OK") {
              self.clearError();
              self.isAuth = true;
              localStorage.setItem("isAuthenticated", true.toString());
              localStorage.setItem("authToken", res.data.access_token);
              localStorage.setItem("emailVerified", false);

              self.closeRegister();

              if (this.$route.query["redirect"]) {
                self.$router.push({ path: self.$route.query["redirect"] });
              } else {
                self.$router.push({ path: "/profile" });
              }
            } else {
              self.error = res.data.message;
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
          });
      }
    },
    forgotPass(data) {
      let self = this;
      this.$http
        .post(process.env.VUE_APP_API + "user/request-password-reset", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = res.data.message;
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 3000);
          } else {
            this.successMessage = "";
            this.error = res.response.data.message;
          }
        })
        .catch((error) => {
          this.successMessage = "";
          this.error = error.response.data.message;
        });
    },
    logout() {
      this.$http
        .post(process.env.VUE_APP_API + "logout")
        .then(() => {
          this.isAuth = false;
          localStorage.removeItem("authToken");
          localStorage.setItem("isAuthenticated", false.toString());
          localStorage.setItem("emailVerified", false.toString());

          this.$router.push({ path: "/" });
          this.openSignInModal();
        })
        .catch(() => {});
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "orders/history")
        .then((res) => {
          this.orderHistory = res.data.payload;
        })
        .catch(() => {});
    },
    async getCurrencies() {
      return this.$http
        .get(process.env.VUE_APP_API + "currencies-data")
        .then((res) => {
          this.currencies = res.data.currencies;
          this.crypto = res.data.crypto;
          this.rates = res.data.rates;
          this.wallets = res.data.wallets;
        })
        .catch(() => {});
    },
    setCheckoutData(
      giveAmount,
      takeAmount,
      takeCurrency,
      giveCurrency,
      paymentMethod,
      fiatToCrypto
    ) {
      this.giveAmount = giveAmount;
      this.takeAmount = takeAmount;
      this.takeCurrency = takeCurrency;
      this.giveCurrency = giveCurrency;
      this.paymentMethod = paymentMethod;
      this.fiatToCrypto = fiatToCrypto;
    },
    orderSubmit() {
      let data = {
        email: this.email,
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        country: this.countryId,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
        paymentType: this.paymentMethod,
        source_asset: this.fiatToCrypto
          ? this.takeCurrency.currency
          : this.takeCurrency.crypto_currency,
        dest_asset: this.fiatToCrypto
          ? this.takeCurrency.crypto_currency
          : this.takeCurrency.currency,
        amount: this.fiatToCrypto ? this.giveAmount : this.takeAmount,
        exchange_rate: this.takeCurrency.price,
        wallet: this.wallet,
      };

      if (!this.fiatToCrypto) {
        data["birth_date"] = this.birthDate;
      }

      this.$http
        .post(process.env.VUE_APP_API + "orders/checkout", data)
        .then((res) => {
          if (res.data.redirect_url) {
            window.location = res.data.redirect_url;
          } else {
            this.changeCheckoutSuccess(true);
            this.closePersonalInfoModal();
            // this.$router.push({path: '/'});
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    openPersonalInfoModal() {
      this.personalInfoModalIsVisible = true;
    },
    closePersonalInfoModal() {
      this.personalInfoModalIsVisible = false;
      this.clearError();
    },
    setPersonalInfo(
      email,
      name,
      surname,
      phone,
      postCode,
      countryId,
      city,
      address,
      birthDate
    ) {
      this.email = email;
      this.name = name;
      this.surname = surname;
      this.phone = phone;
      this.countryId = countryId;
      this.city = city;
      this.address = address;
      this.postCode = postCode;
      this.birthDate = birthDate;
    },
    setWallet(wallet) {
      this.wallet = wallet;
    },
    goToPrivacy() {
      let pageId = "";
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({
            path: "/pages/" + pageId,
            params: { data: pageId },
          });
          this.getTextPageContent(pageId);
        }
      }
    },
    goToTerms() {
      let pageId = "";
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({
            path: "/pages/" + pageId,
            params: { data: pageId },
          });
          this.getTextPageContent(pageId);
        }
      }
    },
    goToAllowedCountries() {
      let pageId = "";
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_countries) {
          pageId = this.textPageList[i].id;
          this.$router.push({
            path: "/pages/" + pageId,
            params: { data: pageId },
          });
          this.getTextPageContent(pageId);
        }
      }
    },
    goToPage(item) {
      this.$router.push({
        path: "/pages/" + item.id,
        params: { data: item.id },
      });
      this.getTextPageContent(item.id);
    },
    goToCookiesPage() {
      this.$router.push({ path: "/pages/" + 7, params: { data: 7 } });
      this.getTextPageContent(7);
    },
    getTextPageContent(id) {
      this.textPageTitle = "";
      this.textPageHeader = "";
      this.textPageContent = "";
      this.$http
        .get(process.env.VUE_APP_API + "static-pages/" + id)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getTextPages() {
      this.$http
        .get(process.env.VUE_APP_API + "static-pages")
        .then((res) => {
          this.textPageList = res.data.payload;
        })
        .catch(() => {});
    },
    getFaq() {
      this.$http
        .get(process.env.VUE_APP_API + "faq")
        .then((res) => {
          this.faq = res.data.payload;
        })
        .catch(() => {});
    },
    toExchange(giveAmount, takeAmount, chosenCrypto) {
      this.giveAmount = giveAmount;
      this.takeAmount = takeAmount;
      this.takeCurrency = chosenCrypto;
      this.$router.push({ path: "/buy-crypto" });
    },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          const payload = res.data.payload;

          this.paymentMethods = payload.payment_methods
            ? payload.payment_methods
            : [];
          this.footerImages = payload.footer_images
            ? payload.footer_images
            : [];
          this.legalInfo = res.data.payload.legal_info;
          this.companyEmail = res.data.payload.support_email;
          this.companyPhone = res.data.payload.support_phone;
          this.billingDescriptor = res.data.payload.billing_descriptor;
          // this.minOrderPriceFiatToCrypto = payload.min_order_fiat_to_crypto ? parseFloat(payload.min_order_fiat_to_crypto) : null;
          this.minOrderPriceCryptoToFiat = payload.min_order_crypto_to_fiat
            ? parseFloat(payload.min_order_crypto_to_fiat)
            : null;
          this.topOrderLimit = payload.top_order_limit
            ? parseFloat(payload.top_order_limit)
            : null;

          const snippet = res.data.payload.google_tag;
          const container = document.createElement("div");
          container.innerHTML = snippet;

          Array.from(container.childNodes)
            ?.filter((element) => element?.tagName === "SCRIPT")
            ?.forEach((element) => {
              document.head.appendChild(element);
            });
        })
        .catch((error) => {
          console.error("Error fetching Google Tag Manager snippet:", error);
        });
    },
    getSocialLinks() {
      this.$http
        .get(process.env.VUE_APP_API + "social-links")
        .then(({ data }) => {
          this.socialLinks = data.payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeCheckoutSuccess(value) {
      this.checkoutSuccess = value;
    },
  },
  created() {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.isAuth = true;
    }
  },
  mounted: async function () {
    await this.fetchLanguages();

    this.getGoogleTagManagerSnippet();
    this.getSocialLinks();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.appDomain = process.env.VUE_APP_DOMAIN;
    this.pageLoaded = true;

    this.hostname = this.getDomainName(window.location.hostname);
    this.getFaq();
    this.getTextPages();

    // await self.$http.get(process.env.VUE_APP_API + 'is-auth')
    // .then((res) => {
    //     self.isAuth = res.data.authenticated
    //     localStorage.setItem('isAuthenticated', res.data.authenticated);
    //
    //     if (!this.isAuth && this.$route.name === 'OrderRequisites') {
    //       self.$router.push({name: 'Home', query: {redirect: self.$route.path}});
    //     }
    // })
    // .catch(() => {
    //
    // })

    this.checkAuthStatus();

    this.getOrderHistory();
    await this.getCurrencies();
  },
  computed: {
    links() {
      return [...this.footerNav, ...this.textPageList];
    },
  },
};
</script>
