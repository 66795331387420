import axios from "axios";
import router from "./router.js";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 5000, // set the default timeout for requests to 5 seconds
});

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  function (config) {
    const lang_id = store.getters["app/currentLanguage"];

    // List of URLs to exclude
    const excludeUrls = [""];

    // Check if the URL is not in the exclude list
    if (!excludeUrls.includes(config.url)) {
      if (config.method === "get") {
        config.params = {
          ...config.params,
        };
      } else if (config.data instanceof FormData) {
        return config;
      } else if (
        ["post", "put", "patch", "POST", "PUT", "PATCH"].includes(config.method)
      ) {
        config.data = {
          ...config.data,
          lang_id,
        };
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const currentRoute = router.currentRoute.name; // Get the current route

    if (error.response.status === 401) {
      if (currentRoute !== "PasswordRestore" && currentRoute !== "BuyCrypto") {
        // Check if it's NOT password reset page
        localStorage.setItem("isAuthenticated", false.toString());
        if (router.currentRoute.name != "Home") {
          router.push({ name: "Home" });
        }
      }
    }

    if (error.response.status === 403) {
      if (currentRoute !== "PasswordRestore") {
        // Check if it's NOT password reset page
        if (
          error.response.data.message ==
          "User identity not approved to submit orders"
        ) {
          router.push({ name: "Profile" });
        } else {
          localStorage.setItem("emailVerified", false.toString());
          if (router.currentRoute.name != "EmailVerificationNotice") {
            this.$router.push({ name: "EmailVerificationNotice" });
          }
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
